<template lang="pug">
.label(data-test="label")
  //- span.label__status
  .label__body(@click="toggle" :style="{color: currentColor}" :class="{'label__body_hoverable': isBtn}")
    slot(name="prepend")
    span.label__text {{ label }}
    v-icon(x-small color="white" data-test="label-active" v-show="isActive") mdi-check
  slot(name="additional")
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    keyValue: {
      required: true
    },
    value: {
      type: Array
    },
    classes: {
      type: Array
    },
    isBtn: {
      type: Boolean,
      default: true
    },
    color: String
  },

  computed: {
    isActive() {
      if(!this.isBtn) return false
      if(this.value.includes(this.keyValue)) return true
      return false
    },

    currentColor() {
      if(this.color) return this.color
      return null
    }
  },

  methods: {
    toggle() {
      if(!this.isBtn) return
      if(this.isActive) {
        let value = this.value
        let index = value.findIndex(item => item === this.keyValue)
        value.splice(index, 1)
        this.$emit('input', value)
        return
      }
      this.$emit('input', [...this.value, this.keyValue])
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  
  display: inline-flex;
  
  position: relative;
  
  width: 100%;
  &__body {
    font-size: 13px;
    width: 100%;
    cursor: pointer;
    background-color: currentColor;
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;
    padding: 2px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: box-shadow .1s ease-in;
    &_hoverable {
      &:hover {
        box-shadow: -6px 0 currentColor
      }
    }
    
  }

  &_active {
    border-color: $primary-color;
    color: $primary-color;
  }

  &_hold {
    background-color: $error-color;
  }
  &_no-dates {
    background-color: $warning-color;
  }
  &_cdn {
    background-color: $label-color;
  }
  &_reservation-e {
    background-color: darken($color: $error-color, $amount: 30%);
  }

  &_acceptance-e {
    background-color: darken($color: $error-color, $amount: 50%);
  }
  &_acceptance-e {
    background-color: darken($color: $error-color, $amount: 50%);
  }
  &_exchange-e {
    background-color: #8a0e67;
  }
  &_exchange-queue {
    background-color: #fc03b6;
  }
  &_queue {
    background-color: #cfe815;
  }
  &_reserve {
    background-color: $success-check-color;
  }
  &_exchange {
    background-color: darken($color: $success-check-color, $amount: 30%);
  }
  &__text {
    color: #f3efeff3;
    font-weight: bold;
  }
}

</style>